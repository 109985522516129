import React from 'react';
import '../Styles/main.css';
import MainPage from './Views/MainPage';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      Two-Points
      </header>
      <div>Main Page</div>;
      <MainPage></MainPage>
    </div>
  );
}

export default App;
